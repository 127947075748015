<template>
  <div>
    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" rounded outlined dark v-on="on" v-bind="attrs">
          Atualizar status
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="updateMultipleStatus(item.value)" v-for="(item, i) in statusItems" :key="i" link>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn class="ml-2 white primary--text" rounded @click="deleteMultiple">
      Excluir
      <v-icon right>fas fa-trash</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
  },
  data: () => ({
    statusItems: [
      { text: "Ativar", value: true },
      { text: "Desativar", value: false },
    ],
  }),
  methods: {
    updateMultipleStatus(value) {
      let profile = this.$store.state.profile[0][0].options[4].permission;
      if (profile.filter((word) => word == "update").length <= 0) {
        //exibe a mensagem de erro
        this.canNot()
      } else {
        let selectedIds = this.selectedItems.map((item) => {
          return item.id;
        });
        this.$api
          .post(`profiles/status/mass`, {
            selectedIds: selectedIds,
            value: value,
          })
          .then(() => {
            this.$store.commit("setModal", {
              show: true,
              component: "success",
              text: "Status alterado com sucesso",
              confirm: () => {
                this.$store.commit("setModal", {
                  show: false,
                });
                this.$emit('modalClicked')
              },
            });
          })
          .catch(() => {
            this.$store.commit("setModal", {
              show: true,
              component: "error",
              text: "Ops! Falha ao tentar alterar status",
              confirm: () => {
                this.$store.commit("setModal", {
                  show: false,
                });
              },
            });
          });
      }
    },
    deleteMultiple() {
      let profile = this.$store.state.profile[0][0].options[4].permission;
      if (profile.filter((word) => word == "delete").length <= 0) {
        //exibe a mensagem de erro
        this.canNot()
      } else {
        let selectedClientsIds = this.selectedItems.map((item) => {
          return item.id;
        });
        console.log(selectedClientsIds);
        this.$store.commit("setModal", {
          show: true,
          component: "warning",
          text: "Atenção!",
          caption: `Deseja mesmo deletar ${this.selectedItems.length} perfis?`,
          confirm: () => {
            this.$api
              .post(`profiles/massdelete`, selectedClientsIds)
              .then(() => {
                this.$store.commit("setModal", {
                  show: true,
                  component: "success",
                  text: "Perfis excluidos com sucesso",
                  confirm: () => {
                    this.$store.commit("setModal", {
                      show: false,
                    });
                    this.$emit('modalClicked')
                  },
                });
              })
              .catch(() => {
                this.$store.commit("setModal", {
                  show: true,
                  component: "error",
                  text: "Ops! Falha ao tentar excluir estes perfis",
                  confirm: () => {
                    this.$store.commit("setModal", {
                      show: false,
                    });
                  },
                });
              });
          },
          negate: () => {
            this.$store.commit("setModal", {
              show: false,
            });
          },
        });
      }
    },
    canNot() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Você não possui permissão para essa ação.',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false
          })
        }
      })
    }
  },
};
</script>